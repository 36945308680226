var isWebP = checkWebP();
var isIntersectionObserver = checkIntersectionObserver();

function checkWebP(){
    if(!![].map && document.createElement('canvas').toDataURL('image/webp').indexOf('data:image/webp') == 0){
    	return true;
    } else {
		function browserCheck(callback){
		    var webP = new Image();
		    webP.onload = webP.onerror = function(){
		        callback(webP.height == 2);
		    };
		    webP.src = 'data:image/webp;base64,UklGRjoAAABXRUJQVlA4IC4AAACyAgCdASoCAAIALmk0mk0iIiIiIgBoSygABc6WWgAA/veff/0PP8bA//LwYAAA';
		};
		return browserCheck(function(result){
			return result;
		});
    }
}

function checkIntersectionObserver(){
	return !(!('IntersectionObserver' in window) ||
		   !('IntersectionObserverEntry' in window) ||
		   !('intersectionRatio' in window.IntersectionObserverEntry.prototype));
}

function generateWebpURL(url){
	url = url.toString();
	['xxlarge','small','medium','large'].some(function(type){
		if(url.indexOf('/' + type + '/') > -1){
			url = url.replace('/' + type + '/','/');
			url += '?$' + type + '$&fmt=webp';
			return true;
		}
	});
	return url;
}

$(".mobile-bar-item-3 #mobile-search-btn").click(function(e){
		e.preventDefault();

		$(".mobile-left-navigation").removeClass('active');
		$(".mobile-bar-icon").removeClass('open');

		var elem = $(".mobile-search-block");
		if(elem.hasClass('active')){
			elem.removeClass('active');
			if(!$(".pt_storefrontHome").length){
				$(".post-header-banner").css('padding-top', '50px');
			}

		}else {
			elem.addClass('active');
			$('.mobile-search-block .ovlbInput').focus();
			if(!$(".pt_storefrontHome").length){
				$(".post-header-banner").css('padding-top', '10px');
			}

		}
		return false;
	});

	$("#mobile-menu-btn").click(function(e){
		e.preventDefault();
		$(".mobile-search-block").removeClass('active');
		var element = $('.mobile-left-navigation');
		var nav = $('#navigation');
		var header = $('#header')
		if(element.hasClass('active')){
			
			header.removeClass('open');
			element.removeClass('active');
			$(this).children().removeClass('open');
			$(window).off('scroll.mobNav');
			
		} else {
			
			element.css('top',nav.height());
			element.addClass('active');
			$(this).children().addClass('open');
			header.addClass('open');
			setMobNav();
			
			$(window).on('scroll.mobNav',function(){
				setMobNav();
			});
			
			function setMobNav(){
				var delta = nav.offset().top - $(window).scrollTop();
				if(delta < 0){
					delta = 0;
				}
				element.css('max-height', $(window).height() - nav.height() - delta);
			}
		
		}
		return false;
	});

	$(".mobile-left-navigation li a.level1, .mobile-left-navigation li a.level2").click(function(e){
		e.preventDefault();
		$(this).addClass('active');
		$(this).siblings().addClass('active');
		$(this).parents(".mobile-left-navigation").scrollTop(0);
	});

	$(".back-button").click(function(){
		$(this).parent('.slide-menu').removeClass('active');
		$(this).parent('.slide-menu').siblings().removeClass('active');
	});
	
var Blazy = function(){};